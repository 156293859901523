import React from 'react';
import { FloatButton } from 'antd';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
const FloatingBtn = () => (
  

  <>
    <FloatButton.Group
      shape="circle"
      style={{
        right: 24,
      }}
    >
      
      <FloatButton className='fb_float' href="https://www.facebook.com/shakthi.decor.9?mibextid=ZbWKwL" target='_blank' icon={<FaFacebook className='float_fb ' />} />
      <FloatButton className='insta_float' href='https://www.instagram.com/shakthidecor/?igsh=ZmtjdGl4MnhzdjFv' target='_blank' icon={<FaInstagram className='float_insta'/>} />
      <FloatButton className='whats_float' icon={<IoLogoWhatsapp className='float_whats'/>} onClick={() => window.open('https://wa.me/9944808181?text=How can I help you', '_blank')}/>
      <FloatButton.BackTop visibilityHeight={0} />
    </FloatButton.Group>
  </>
);
export default FloatingBtn;